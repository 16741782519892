@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scrollbar-width: thin;
  scrollbar-color: #c7c8c5 #ffffff00;
}

@layer base {
  :root {
    --backdrop: 247 240 255; /* nullifyBackgroundLavender */
    --background: 255 255 255; /* white */
    --foreground: 0 0 0; /* black */

    --primary: 95 30 219; /* nullifyIntensePurple */
    --primary-foreground: 255 255 255; /* white */

    --secondary: 234 254 177; /* nullifyEmphasisNeonYellow */
    --secondary-foreground: 0 0 0; /* black */

    --destructive: 239 68 68; /* red.500 */
    --destructive-foreground: 255 255 255; /* white */

    --muted: 243 244 246; /* gray.100 */
    --muted-foreground: 113 113 122; /* gray.500 */

    --accent: 218 212 255; /* nullify.200 */
    --accent-foreground: 0 0 0; /* black */

    --popover: 255 255 255; /* white */
    --popover-foreground: 0 0 0; /* black */

    --card: 255 255 255; /* white */
    --card-foreground: 0 0 0; /* black */

    --border: 236 223 254; /* nullifyBackgroundLinesPurple */
    --input: 236 223 254; /* nullifyBackgroundLinesPurple */
    --ring: 0 0 0; /* black */

    --radius: 0.5rem;

    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  /* .dark {
    --background: theme('colors.nullify.950');
    --foreground: theme('colors.nullify.50');

    --card: theme('colors.nullify.950');
    --card-foreground: theme('colors.nullify.50');

    --popover: theme('colors.nullify.950');
    --popover-foreground: theme('colors.nullify.50');

    --primary: theme('colors.nullify.50');
    --primary-foreground: theme('colors.black');

    --secondary: theme('colors.nullify.800');
    --secondary-foreground: theme('colors.nullify.50');

    --muted: theme('colors.gray.800');
    --muted-foreground: theme('colors.gray.400');

    --accent: theme('colors.nullify.800');
    --accent-foreground: theme('colors.nullify.50');

    --destructive: theme('colors.red.900');
    --destructive-foreground: theme('colors.nullify.50');

    --border: theme('colors.nullify.800');
    --input: theme('colors.nullify.800');
    --ring: theme('colors.nullify.300');
  } */
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-backdrop text-backdrop-foreground;
  }
}

@layer components {
  .nullifyContainer {
    @apply mx-auto w-full px-10 hd:w-4/5;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-backdrop text-foreground;
  }
}
